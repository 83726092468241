$primary-color: #18558A
$timesNewRomanFont: "Times New Roman", Times, serif

.maincomponent
    // background-color: $primary-color
    height: 100vh
    color: black
    font-size: 12px
.navcomponent
    background-color: $primary-color
    height: 6%
    width: 100%
    padding: 0.5%
    display: flex
    flex-direction: row
.navcompsub
    display: flex
    flex-direction: row
    // background-color: red
    color: white
    width: 100%
    justify-content: space-between
.icon-name
    font-size: 20px
    margin-left: 15px
    margin-right: 15px
.main-subcomponent
    // background-color: green
    height: 94%
    display: flex
    flex-direction: row

.sub-component1
    background-color: $primary-color
    width: 6%
    padding: 5px
.sub-component2
    background-color: $primary-color
    width: 96%
.tabcomponent
    background-color: #264966
    height: 6%
    padding-top: 5px
    display: flex
    flex-direction: row
    // overflow-x: scroll

.areacomponent
    background-color: #ededed
    height: 90%

.navicon
    border-radius: 6px
    border: 1px solid skyblue
    background-color: white

// Side Button CompStyle
.main-sidebutton
    color: white
    padding: 0px
    border-bottom: 2px solid #264966
    padding-bottom: 5px
    margin-top: 5px
    margin-bottom: 5px
.main-sidebutton:hover
    background-color: #87cfeb81
.sidebutton-icon
    // background-color: red
    font-size: 25px
    text-align: center

.sidebutton-text
    // background-color: blue
    overflow-wrap: break-word
    text-align: center
    font-size: 10px
.custom-tab:not(.active)
    color: white
    // background-color: #your-unselected-background-color
.custom-tab
    color: white
    // background-color: #your-unselected-background-color
.main-sidebutton
    position: relative

.dropdown-menu
    position: absolute
    top: 100%
    right: 0
    background-color: #fff
    border: 1px solid #ccc
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    z-index: 1

.dropdown-item
    padding: 10px
    cursor: pointer
.dumcomp
    background-color: #D3D3D3
    padding: 10px
    height: 85vh
    display: flex
    flex-direction: row
    justify-content: space-evenly
    flex-wrap: wrap
.usericon
    font-size: 28px
.usericon2
    font-size: 26px

// 
// .outerbox-circle
//     height: 100px
//     width: 100px
//     background-color: white
//     border-radius: 100%
//     justify-content: center
//     text-align: center
//     border: 5px solid royalblue
.outerbox-circle
    height: 200px
    width: 200px
    background-color: #d3d3d344
    border-radius: 50%
    display: flex
    justify-content: center
    align-items: center
    text-align: center
    border: 10px solid #7BD3EA

.innerbox-circle
    display: flex
    flex-direction: column
    align-items: center
    font-size: 20px
.dashcomp
    background-color: #d3d3d344
    padding: 10px
    height: 100%
    display: flex
    flex-direction: row

    // justify-content: space-evenly
    // flex-wrap: wrap
.dashcomp1
    // background-color: red
    width: 85%
    overflow-y: scroll
.dashcomp2
    // background-color: white
    justify-content: space-evenly
    width: 15%
    padding-left: 10px
    padding-right: 10px
    padding-bottom: 10px
.circledatabox
    background-color: white
    padding: 10px
    display: flex
    flex-direction: row
    justify-content: space-evenly
    border-radius: 10px

.boxmaincomp
    width: 100%
    background-color: #5C8374
    display: flex
    flex-direction: column
    height: 17%
    margin-top: 5%
    border-radius: 10px
    padding: 5%
    border: 0.5px solid grey
    transition: 0.5s ease-in-out
.boxmaincomp1
    background-color: white
    height: 30%
    border-radius: 5px
    justify-content: center
    text-align: center
    font-size: 17px

.boxmaincomp2
    // background-color: yellow
    height: 70%
    justify-content: center
    text-align: center
    // padding-top: 10%
    font-size: 40px
    color: white

.boxmaincomp:hover
    cursor: pointer
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px
.dashcompchart
    // background-color: white
    height: 60%
    margin-top: 10px
    border-radius: 10px
    // padding: 5px
.dashcompchartcomp1
    display: flex
    flex-direction: row
    height: 100%
    // margin: 0px
.dashcompchartcomp11
    width: 40%
    background-color: white
    margin: 5px
    // padding: 5px
    border-radius: 10px
    align-items: center
    // justify-content: center
    // text-align: center
    display: flex
    flex-direction: column
    overflow-y: auto
.dashcompchartcomp12
    width: 60%
    background-color: white
    margin: 5px
    padding-top: 12px
    border-radius: 10px
    align-items: center
    justify-content: center
    text-align: center
    display: flex
    // overflow: scroll
.btntab
    // height: 70%
    background-color: white
    margin-left: 5px
    border: none
    border-radius: 5px
    display: flex
    flex-direction: row
    justify-content: center
    align-self: center
.btntab2
    background-color: transparent
    // margin-left: 5px
    border: none
    // border-radius: 5px
    display: flex
    color: white
    flex-direction: row
    justify-content: center
    align-self: center
    // border-bottom: 1px solid white

// .ant-table table 
//      font-size: 8px
//      color: black

.btntab2active
    background-color: white
    // margin-left: 5px
    border: none
    // border-radius: 5px
    display: flex
    color: black
    flex-direction: row
    justify-content: center
    align-self: center
    cursor: pointer
    // border-top-left-radius: 5px
    // border-bottom-left-radius: 5px
.btntab2activespan
    // background-color: white
    // margin-left: 5px
    border: none
    // border-radius: 5px
    display: flex
    width: 10px
    cursor: pointer
    color: black
    flex-direction: row
    justify-content: center
    align-self: center
    margin-right: 5px
    // border-top-right-radius: 5px
    // border-bottom-right-radius: 5px

.component-sub
    padding: 2%
    // border: 0.2px solid grey
    height: 100%
    // background-color: blue
    display: flex
    flex-direction: row
    border: 1px solid grey

.footercomponent
    display: flex
    flex-direction: row
    color: white
    justify-content: space-between
    padding-left: 10px
    padding-right: 10px
    font-size: 12px
    padding-top: 5px
.registercomp1
    border: 1px solid grey
    width: 100%
    border-radius: 10px
    display: flex
    flex-direction: row
    padding: 1%

.multistepmaincontainer
    height: 100%
    width: 100%
    overflow-y: scroll
    // background-color: skyblue
.multistephead
    // background-color: red 
    text-align: center
    color: $primary-color
.multistepcontainer1
    display: flex
    padding: 1%
    flex-direction: row

.multistepsubcontainer1
    width: 100%

.multiinputfield
    width: 70%
    outline: none
    border: 0.5px solid grey
    border-radius: 1px

// .multisteptabletd1
//     width: 8%
//     // background-color: blue 
//     // text-align: center
// .multisteptabletd2
//     // background-color: red 
//     width: 30%
//     align-items: center
//     justify-content: center
//     text-align: center

.multiformbtn
    border: none
    background-color: $primary-color
    border-radius: 5px
    color: white
.multisteptabletr
    background-color: red
    margin-bottom: 100px
.multisteptable
    display: grid
    grid-template-columns: auto auto
    gap: 1%

    width: 100%
    height: 100%

    &row
        display: grid
        grid-template-columns: 34% 50%
        // gap: 1%

    &cell
        padding: 5px
        background-color: red

    &cell1
        font-weight: bold
        width: 50%

.multiinputfield
    width: 100%
    padding: 5px
    box-sizing: border-box
.multiformfooter
    // background-color: red 
    margin-top: 2%
    justify-content: center
    align-items: center
    text-align: center
    padding: 5px
.loginmaincontainer
    width: 100%
    height: 100vh
    background-color: white
    display: flex
    justify-content: center
    align-items: center
    background-image: url('https://img.freepik.com/free-vector/abstract-background-design_361591-2108.jpg?t=st=1732423910~exp=1732427510~hmac=b413d8c1e37edc4ca292caa9a24ea29b4499040518d8d7263130d8fdc91f9daf&w=1380')
    background-size: cover
    background-position: center
    background-repeat: no-repeat
.loginsubcontainer
    background-color: white
    width: 60%
    height: 70vh
    display: flex
    flex-direction: row
    border-radius: 5px
    padding: 1px
    // border: 0.1px solid grey
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px
.loginsubcontainer1
    width: 50%
    background-color: #ccebf5
    border-top-left-radius: 5px
    border-top-right-radius: 20%
    border-bottom-left-radius: 5px
    border-bottom-right-radius: 20%
    display: flex
    justify-content: center
    align-items: center

.loginsubcontainer2
    width: 50%
    background-color: white
    display: flex
    justify-content: center
    align-items: center
    // background-color: red
.imagelogin
    height: 300px
.lgmaincont
    // background-color: blue
    justify-content: space-evenly
    // height: 200px

.lgmainconthead
    padding: 5px
    font-size: 35px
    margin-bottom: 1%
.loginipt
    margin-bottom: 30px
    // height: 50px
.signbtn
    border: none
    background-color: $primary-color
    color: white
    padding: 10px
    border-radius: 10px
    width: 50%
.lgmaincontfoot
    justify-content: center
    text-align: center

    // background-color: red 
.lgmainconthead
    font-family: 'Audiowide'
    color: $primary-color
    justify-content: center
    text-align: center
.btntabmain
    display: flex
    margin-left: 5px
    margin-right: 5px
.btntabmainactive
    background-color: white
    display: flex
    margin-left: 5px
    margin-right: 5px
    color: black
    padding: 0px
    border-radius: 10px

.component-sub1
    display: flex
    flex-direction: column
    background-color: #ffffff62
    width: 100%
    padding: 5px
    border: 0.5px solid grey
    border-radius: 10px
    overflow-y: scroll
.roleheader
    display: flex
    justify-content: space-evenly
    padding: 5px
    // background-color: red 
    align-items: center
    text-align: center

.selectoptiontrole
    width: 40%
.buttoncompnent1
    background-color: $primary-color
    color: white
    border: none
    padding: 6px
    // font-size: 12px
    border-radius: 5px
    // width: 10%
    margin: 5px
    padding-left: 20px
    padding-right: 20px
#buttoncompnent1
    border: none
    padding: 5px
    // font-size: 12px
    border-radius: 5px
    // width: 10%
    margin: 4px
.rolemaincontainer
    // display: flex
    // background-color: red
    height: 85%

    justify-content: center
    padding: 1%
    // align-items: center
    // background-color: red 
.ant-table table
.roledataheadder
    width: 100%
    justify-content: center
    text-align: center
    background-color: $primary-color
    color: White
    // margin: 

    margin-bottom: 5px

.componentsmaincont
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
.component-sub1::-webkit-scrollbar
    width: 8px

.component-sub1::-webkit-scrollbar-thumb
    background-color: #808080
    border-radius: 8px

.component-sub1::-webkit-scrollbar-track
    background-color: #ffffff62
    border-radius: 10px
.Billcompmain
    display: flex
    // flex-wrap: wrap
    justify-content: center

.Billcompmain1
    width: 35%
    padding: 1%
    background-color: white
    margin: 5px
    border-radius: 6px
    background-color: #fafafa
    border: 1px solid grey
.Billcompmain2
    width: 61%
    padding: 1%
    background-color: #fafafa
    margin: 5px
    border-radius: 6px
    border: 1px solid grey

.textipbill
    margin-top: 2px
.tablecontainer
    padding: 10px

.multistepcontainerbtn
    width: 90%
    display: flex
    justify-content: flex-end
    // margin-right: 10px
    // background-color: red 
.btn-primary
    font-size: 12px
.btn-success
    font-size: 12px
#mySwitch
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
  background-color: red

  &:checked
    background-color: #28a745

.ant-menu-item-icon
        font-size: 24px
        margin-bottom: 6px

.ant-menu-item > a
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.menuItemCustomStyle:hover
    background-color: transparent 

.dashcompchartcomp12edit
    width: 60%
    background-color: white
    margin: 5px
    // padding-top: 12px
    border-radius: 10px
    // align-items: center
    // justify-content: center
    text-align: center
    // display: flex
    overflow: auto
    // overflow-x: hidden
.dashcompchartcomp12edit::-webkit-scrollbar
    width: 8px

.dashcompchartcomp12edit::-webkit-scrollbar-thumb
    background-color: #8080806f
    border-radius: 8px

.dashcompchartcomp12edit::-webkit-scrollbar-track
    background-color: #ffffff62
    border-radius: 10px

    // 
.dashcompchartcomp11::-webkit-scrollbar
    width: 8px

.dashcompchartcomp11::-webkit-scrollbar-thumb
    background-color: #8080806f
    border-radius: 8px

.dashcompchartcomp11::-webkit-scrollbar-track
    background-color: #ffffff62
    border-radius: 10px
.Billcompmain1chart
    width: 35%
    padding: 0.5%
    // background-color: white
    // margin: 5px
    display: flex
    flex-direction: column
    border-radius: 6px
    // background-color: #fafafa
    // border: 1px solid grey
.Billcompmain11
    width: 100%
    padding: 3%
    height: 30%
    background-color: white
    // margin: 5px
    border-radius: 6px
    background-color: #fafafa
    border: 1px solid grey
.Billcompmain12
    width: 100%
    padding: 1%
    height: 70%
    background-color: white
    // margin: 5px
    border-radius: 6px
    background-color: #fafafa
    border: 1px solid grey
.loaderbackground
    background-color: "white"
    // background: linear-gradient(135deg, #18558A, #0E2C47)
    width: 100%
    height: 100vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    text-align: center
.imagelogoloader
    border-radius: 10px
    // border: 0.5px solid #18558A
.tabletdsubjecttable
    border: 0.5px solid #8b8f922d
    padding: 3px
    &:hover
        background-color: #8b8f922d

.subjecttabletr
    &:hover
         background-color: #8b8f922d 


.sectionallocationmcomp
    width: 100%
    height: 100%
    // background-color: red 
.sectionallocationmcompcontainer
    width: 100%
    height: 80%
    // background-color: red
    overflow: auto
    // border: 0.5px solid #C9CCD5
    border-radius: 10px
    padding: 1%
    display: grid
    grid-template-columns: auto auto auto auto
    gap: 10px

.sectionallocationmcomphead
    width: 100%
    // background-color: royalblue
    padding: 1%
    display: flex
    justify-content: space-around
.container_display_std_malloc
    display: flex
    padding: 0.5px
    border: 0.5px solid grey
    justify-content: space-around
    align-items: center
    background-color: white
    border-radius: 5px
    height: 40px
    width: 80%
    cursor: pointer
.imagelogin2
    display: none
@media only screen and (max-width: 600px)
    .loginsubcontainer1
        display: none
    .loginsubcontainer2
        width: 100%
        padding: 2%
    .loginsubcontainer
        width: 80%
    .imagelogin2
        display: block
        width: 100px
    .lgmaincont
        justify-content: center
        align-items: center
        display: flex
        flex-direction: column
    .signbtn
        width: 200px
    .sub-component1
        display: none
    .dashcomp
        display: flex
        flex-direction: column-reverse
    .dashcomp2
        display: flex
        flex-direction: row
        width: 100%
        overflow: scroll
    .dashcomp1
        width: 100%
    .sub-component2
        width: 100%
    .boxmaincomp
        height: 70%
    .boxmaincomp1
        font-size: 20px
        background-color: rgba(white, 0)
        color: white
    .boxmaincomp2
        // font-size: 11px
        display: none
    .circledatabox
        overflow: scroll
        justify-content: flex-start 
    .outerbox-circle
        width: 300px
        height: 100px
    .innerbox-circle
        font-size: 11px
    .boxmaincomp1
        font-size: 10px
    .dashcompchartcomp11
        font-size: 10px
    .dashcompchartcomp11
        width: 70%
    .dashcompchartcomp12
        width: 30%
    .icon-name
        font-size: 15px
    .main-sidebutton
        color: black
        border-bottom: 0px solid white
    .navcomponent
        align-items: center